<template>
  <header
    v-if="isShow"
    :style="whenscrollY" >
    <div
      @click="clickMenu(false)"
      class="logo">
      <router-link
        id="homeMenuHeader"
        :to="{ name: 'home' }" >
        <img
          src="@/assets/icons/logo.svg"
          alt="Logo truecoffee go">
      </router-link>
    </div>

    <div
      class="icon-menu"
      @click.prevent="clickMenu(true)">
      <div class="box-1"></div>
      <div class="box-2"></div>
      <div class="box-3"></div>
    </div>

    <div
      class="menu"
      :class="[openMenu ? 'openMenu' : 'closeMenu']">
      <div class="top">
        <div class="head">
          <div
            class="icon-close"
            @click.prevent="clickMenu(false)">
            <div class="line-1"></div>
            <div class="line-2"></div>
          </div>
        </div>

        <div
          @click.prevent="clickMenu(false)"
          class="logo">
          <router-link
            id="mainMenuHome"
            :to="{ name: 'home' }" >
            <img
              src="@/assets/icons/logo-white.svg"
              alt="Logo truecoffee go">
          </router-link>
        </div>

        <nav class="link-page">
          <router-link
            v-for="(menu, i) in menus"
            :key="i"
            :id="menu.id_header"
            :to="{ name: menu.router_name }"
            @click.native="clickMenu(false)">
            {{ menu.title }}
          </router-link>
        </nav>
      </div>

      <div class="bottom">
        <nav class="link-social">
          <div
            v-for="(social, i) in socials"
            :key="i"
            class="social">
            <a
              :id="social.id"
              :href="social.link"
              target="_blank" >
              <font-awesome-icon
                :icon="['fab', social.icon]"
                class="icon" />
              <!-- <img :src="social.icon"> -->
            </a>
          </div>
        </nav>

        <!-- <div class="app">
          <a
            id="mainMenuAppiOS"
            data-name="App Store"
            href="itms-apps://itunes.apple.com/th/app/truecoffee-go/id1483000620"
            target="_blank" >
            <img src="@/assets/images/app-store-badge.png">
          </a>
          <a
            id="mainMenuAppAndroid"
            data-name="Play Store"
            href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=th.co.truecorp.truecoffee.go&hl=en_US"
            target="_blank" >
            <img src="@/assets/images/google-play-badge.png">
          </a>
        </div> -->

        <div class="copyright">
          <p>© True Coffee</p>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
import menus from '@/resources/menus.json'

export default {
  data () {
    return {
      isShow: true,
      scrollY: 0,
      openMenu: false,
      menus: menus,
      socials: [
        {
          icon: 'line',
          link: 'http://line.me/ti/p/%40truecoffeego',
          id: 'lineSocialHeader'
        },
        {
          icon: 'facebook-f',
          link: 'https://www.facebook.com/truecoffeego',
          id: 'facebookSocialHeader'
        },
        // {
        //   icon: 'twitter',
        //   link: ''
        // },
        {
          icon: 'instagram',
          link: 'https://www.instagram.com/truecoffeego_',
          id: 'instagramSocialHeader'
        }
      ]
    }
  },

  computed: {
    whenscrollY () {
      return this.scrollY > 0 ? { backgroundColor: 'rgba(255, 255, 255, 0.8)' } : {}
    }
  },

  methods: {
    checkShow () {
      const routerName = this.$route.name

      if (routerName === 'landing') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },

    onScroll () {
      this.scrollY = window.top.scrollY
    },

    clickMenu (check) {
      this.openMenu = check

      if (!check) {
        window.scroll(0, 0)
      }
    }
  },

  watch: {
    '$route' (route) {
      this.checkShow()

      window.scroll(0, 0)

      if (route.name === 'home') {
        this.openMenu = false
      }
    }
  },

  created () {
    this.checkShow()
    this.scrollY = window.top.scrollY
  },

  mounted () {
    window.addEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

header {
  transform:translateZ(1px);
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  transition: 800ms $ease;

  .logo {
    img {
      width: auto;
      height: 40px;
    }
  }

  .icon-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .box-1,
    .box-2,
    .box-3 {
      margin-top: 6px;
      height: 2px;
      background-color: $color-main;
    }

    .box-1 {
      margin-top: 0;
      width: 28px;
    }

    .box-2 {
      width: 42px;
    }

    .box-3 {
      width: 28px;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    overflow: scroll;
    position: absolute;
    top: 0;
    left: 0;
    padding: 22px 16px 12px 16px;
    width: 100vw;
    height: 100vh;
    background-color: $color-main;
    transition: 1000ms $ease;

    @media screen and (min-width: 768px) {
      justify-content: space-between;
      padding: 35px 72px 15px 72px;
    }

    .top,
    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .top {
      padding-bottom: 22px;

      @media screen and (min-width: 768px) {
        height: 100%;
      }

      .head {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .icon-close {
          cursor: pointer;
          width: 50px;
          height: 15px;

          .line-1,
          .line-2 {
            width: 50px;
            height: 2px;
            background-color: white;
            transition: 800ms $ease;
          }

          .line-1 {
            transform: rotate(15deg)
                       translate(2px, 6px);
          }

          .line-2 {
            transform: rotate(-15deg)
                       translate(-1px, 4px);
          }
        }

        .icon-close:hover {
          .line-1 {
            transform: rotate(45deg)
                       translate(4px, 5px);
          }

          .line-2 {
            transform: rotate(-45deg)
                       translate(-4px, 3px);
          }
        }
      }

      .logo {
        margin: 40px 0;

        @media screen and (min-width: 768px) {
          margin: 60px 0;
        }

        a {
          img {
            height: 52px;
            transition: 800ms $ease;

            @media screen and (min-width: 768px) {
              height: 76px;
            }
          }

          img:hover {
            transform: scale(1.5);
          }
        }
      }

      .link-page {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }

        a {
          margin-top: 30px;
          font-family: 'DB Ozone X Bd';
          letter-spacing: 1px;
          font-size: 20px;
          color: white;
          transition: 800ms $ease;

          @media screen and (min-width: 768px) {
            margin-top: 0;
            margin: 0 31px;
          }
        }

        a:first-child {
          margin-top: 0;
        }

        a:hover {
          transform: scale(2);
        }
      }
    }

    .bottom {
      margin-top: 300px;
      // margin-bottom: 100px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
        height: auto;
      }

      .link-social {
        cursor: pointer;
        display: flex;
        padding-bottom: 52px;

        @media screen and (min-width: 768px) {
          padding-bottom: 0;
        }

        .social {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 12px 6px;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          // border: 1px solid white;

          a {
            .icon {
              font-size: 22px;
              color: white;
            }
          }
        }
      }

      .app {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          flex-direction: row;
        }

        a {
          margin: 12px 0;

          @media screen and (min-width: 768px) {
            margin: 30px 0 100px 0;
          }

          img {
            width: 173px;

            @media screen and (min-width: 768px) {
              margin: 0 12px;
              width: auto;
              height: 52px;
            }
          }
        }
      }

      .copyright {
        margin-top: 160px;

        @media screen and (min-width: 768px) {
          margin-top: 0;
        }

        p {
          font-size: 18px;
          letter-spacing: 1px;
          color: white;
        }
      }
    }
  }

  .openMenu {
    transform: translateY(0)
               scale(1);
  }

  .closeMenu {
    transform: translateY(-200vh)
               scale(2);
  }
}
</style>
